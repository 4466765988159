import React, { useContext } from 'react';
import { ThemeContext, ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getImgixUrl } from '../../../../../../helpers';
import {
  CarouselItemBackgroundLink,
  Overlay,
  SlideWrapper,
} from '../../styled';
import {
  HalfStackedDisplayContent,
  LargeImageDisplayContent,
  LargeVideoDisplayContent,
} from '../../../HeroModule/displayStyles';
import {
  CENTRE,
  HALF_STACKED,
  LARGE_IMAGE,
  LARGE_VIDEO,
} from '../../../HeroModule/constants';

export function CarouselItem(props) {
  const {
    item: {
      background: { path: backgroundImage } = {},
      colorType = '',
      displayType = '',
      textAlignment = CENTRE,
      theme,
      primaryLink,
    } = {},
    forcePlay,
  } = props;
  const { tenantId } = useSelector(({ pageConfig }) => pageConfig);
  const themeConfig = useContext(ThemeContext);
  const { heroCarouselModule } = themeConfig.siteBuilderModules;

  const vStyles = heroCarouselModule.visualStyles[displayType]?.[colorType];
  const dStyles = heroCarouselModule.displayStyles[displayType];

  const backgroundPath = getImgixUrl(tenantId, backgroundImage);

  return (
    <ThemeProvider
      theme={{
        module: { vStyles, dStyles, ...theme, alignStyle: textAlignment },
      }}
    >
      <SlideWrapper
        className="lazy"
        data-background-image={
          displayType === HALF_STACKED ? undefined : backgroundPath
        }
        data-testid="hero-carousel-slide-wrapper"
      >
        {displayType !== HALF_STACKED && <Overlay />}

        <CarouselItemBackgroundLink
          type="link"
          labelOverwrite=""
          ctaConfig={primaryLink}
        />

        {displayType === LARGE_IMAGE && (
          <LargeImageDisplayContent section={props.item} />
        )}
        {displayType === LARGE_VIDEO && (
          <LargeVideoDisplayContent
            section={props.item}
            forcePlay={forcePlay}
          />
        )}
        {displayType === HALF_STACKED && (
          <HalfStackedDisplayContent section={props.item} />
        )}
      </SlideWrapper>
    </ThemeProvider>
  );
}

CarouselItem.propTypes = {
  item: PropTypes.shape({
    displayType: PropTypes.oneOf([
      'LARGE_IMAGE',
      'LARGE_VIDEO',
      'HALF_STACKED',
    ]),
    colorType: PropTypes.string.isRequired,
    textAlignment: PropTypes.oneOf(['LEFT', 'RIGHT', 'CENTRE']),
    background: PropTypes.shape({
      path: PropTypes.string,
    }),
    pageConfig: PropTypes.shape({
      tenantId: PropTypes.string,
    }),
  }).isRequired,
};
