import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { HeroCarouselModuleWrapper } from './styled';
import { SiteBuilderModule } from '../sharedComponents';
import Carousel from '../../../shared/Carousel/Carousel';
import { CarouselItem } from './innerComponents/CarouselItem';

const defaultCarouselSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        arrows: false,
      },
    },
  ],
};

export function HeroCarouselModule(props) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [prevSlideIndex, setPrevSlideIndex] = useState(0);

  const carouselSettings = useMemo(
    () => ({
      ...defaultCarouselSettings,
      afterChange: setCurrentSlideIndex,
      beforeChange: setPrevSlideIndex,
    }),
    [],
  );

  const { section: { subsections } = {} } = props;

  const forcePlay =
    prevSlideIndex === subsections.length - 1 && currentSlideIndex === 0; // This is a fix for the slider's infinite options and IntersectionObserver issue. (Force play from last slide to first one)

  return (
    <SiteBuilderModule section={props.section}>
      <HeroCarouselModuleWrapper data-testid="hero-carousel-module-wrapper">
        <Carousel embedSettings={carouselSettings}>
          {subsections.map((carouselItemProps, index) => {
            return (
              <CarouselItem
                key={index}
                item={carouselItemProps}
                forcePlay={forcePlay}
              />
            );
          })}
        </Carousel>
      </HeroCarouselModuleWrapper>
    </SiteBuilderModule>
  );
}

HeroCarouselModule.propTypes = {
  section: PropTypes.shape({
    shown: PropTypes.bool,
  }).isRequired,
};

export default HeroCarouselModule;
